import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { hide, show } from "redux-modal";
import { GTContext } from "../../../GTContext/GTContext";
import ws, {
  isGamePlayed,
  GNotificationsI,
  WSEvent,
  balanceWasChanged,
} from "../../../services/ws";
import { RootState } from "../../../store";
import { updateGameBalance } from "../../../store/me/actions";
import { GameUserStateI } from "../../../store/me/reducer";
import { RoutsGameE } from "../../../utils/gameRoutsName";
import { GameToasts } from "../../../utils/gameToasts";
import { useGameProf } from "../../../utils/hooks/gameHooks/useGameProf";
import {
  useAccount,
  useConnector,
  useWatchConnection,
} from "../../../utils/hooks/useAccount";
import useErrorMessage from "../../../utils/hooks/useErrorMessage";
import useMe, { Me } from "../../../utils/hooks/useMe";
import useMediaQuery from "../../../utils/hooks/useMediaQuery";
import {
  GAME_BUY_SOUL,
  GAME_CONNECT_MODAL,
  GAME_TRANSFER_SOUL,
} from "../../../utils/modalGameNames";
import { AppContext, SoundTypeE } from "../../HOC/GameHOC/AppContextProvider";

export interface HeaderControl {
  readonly me?: Me;
  readonly showNotif: boolean;
  readonly openAllNotif: boolean;
  readonly gameProf: GameUserStateI;
  readonly notif: GNotificationsI[];
  readonly newNotif: boolean;

  onDisconnect(): void;
  showConnectModal(): void;
  showBuyModal(): void;
  showNotifications(): void;
  onOpenAllNotif(): void;
  onCloseNotif(): void;
  onCloseAllNotif(): void;
  showTransferModal(): void;
}

export const battleNotifRes = (n: "L" | "W" | "D") => {
  switch (n) {
    case "W":
      return "Won";
    case "L":
      return "Lose";
    case "D":
      return "Draw";
  }
};

function useHeaderGameControl(): HeaderControl {
  const token = useSelector((state: RootState) => state.auth.token?.token);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { playSound } = useContext(AppContext);
  const { events } = useContext(GTContext);

  const [showNotif, setShowNotif] = useState<boolean>(false);
  const [openAllNotif, setOpenAllNotif] = useState<boolean>(false);
  const [notif, setNotif] = useState<GNotificationsI[]>(events);
  const [newNotif, setNewNotif] = useState<boolean>(false);
  const connector = useConnector();
  useWatchConnection(console.error);
  useErrorMessage();
  const me = useMe();
  const gameProf = useGameProf();
  const mob425 = useMediaQuery("max", 425);
  const tok = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (me) {
      const expires = tok?.expires;
      if (expires && expires < new Date().getTime()) {
        connector.disconnect();
      }
    }
  });

  useEffect(() => {
    if (events.length > 0) {
      return setNotif(events);
    }
  }, [events]);

  useEffect(() => {
    if (location.pathname === RoutsGameE.Home) {
      if (me?.Address) {
        navigate(`${RoutsGameE.Home}?user=${me.Address}`);
      } else {
        navigate(RoutsGameE.Home);
      }
    }
  }, [me]);

  const addNotif = (ev: WSEvent) => {
    if (isGamePlayed(ev) && !ev.old) {
      setNewNotif(true);
      setNotif(
        [...notif, ev.Event]
          .filter((item, i, arr) =>
            arr[i + 1] ? item.RoomId !== arr[i + 1].RoomId : 1
          )
          .sort((a, b) => b.Played - a.Played)
      );
      return;
    }
  };
  useEffect(() => {
    if (!token) return;
    ws.addListener(addNotif);
    return () => ws.removeListener(addNotif);
  }, [token]);

  useEffect(() => {
    // отключаем красную иконку
    if (showNotif || openAllNotif) setNewNotif(false);
  }, [showNotif, openAllNotif, notif]);

  const onDisconnect = () => {
    navigate(RoutsGameE.Home);
    connector.disconnect();
  };

  const showConnectModal = () => dispatch(show(GAME_CONNECT_MODAL));
  const showBuyModal = () => {
    playSound(SoundTypeE.SMALL);
    dispatch(show(GAME_BUY_SOUL));
    dispatch(hide(GAME_TRANSFER_SOUL));
  };
  const showNotifications = () =>
    mob425 ? setOpenAllNotif(!openAllNotif) : setShowNotif(!showNotif);
  const onOpenAllNotif = () => {
    setOpenAllNotif(!openAllNotif);
    onCloseNotif();
  };
  const onCloseNotif = () => setShowNotif(false);
  const onCloseAllNotif = () => setOpenAllNotif(false);
  const showTransferModal = () => {
    dispatch(show(GAME_TRANSFER_SOUL));
    dispatch(hide(GAME_BUY_SOUL));
  };

  return {
    me,
    showNotif,
    openAllNotif,
    gameProf,
    notif,
    newNotif,

    onDisconnect,
    showConnectModal,
    showBuyModal,
    showNotifications,
    onOpenAllNotif,
    onCloseNotif,
    onCloseAllNotif,
    showTransferModal,
  };
}

export default useHeaderGameControl;
