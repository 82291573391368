import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hide, show } from "redux-modal";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import {
  GAME_BUY_SOUL,
  GAME_TRANSFER_SOUL,
} from "../../../../../utils/modalGameNames";
import {
  AppContext,
  SoundTypeE,
} from "../../../../HOC/GameHOC/AppContextProvider";
import erc20 from "../../../../../services/bch/erc20";
import { GameUserStateI } from "../../../../../store/me/reducer";
import { deposit } from "../../../../../services/api/game/gameSoul";
import wallet from "../../../../../services/wallet";
import { Toasts } from "../../../../../utils/toasts";
import { updateGameBalance } from "../../../../../store/me/actions";
import BigNumber from "bignumber.js";
import { DDOptionI } from "../../../GameFolder/GameDropdown/GameDropdown.props";
import { isServerError } from "../../../../../services/api/setting";
import { WALLET_FOR_BUY_SOUL } from "../../../../../config";
import { withdrow } from "../../../../../services/api/staking";
import mkError from "../../../../../utils/mkError";

interface TransferSoulControlI {
  readonly gameProf: GameUserStateI;
  readonly options: DDOptionI[];
  readonly selectorFrom: DDOptionI;
  readonly valueFrom: number;
  readonly selectorTo: DDOptionI;
  readonly isSysWallet: boolean;
  readonly isEnoughSoul: boolean;
  readonly isLoading: boolean;

  openBuyModal(): void;
  setSelectorFrom(selector: DDOptionI): void;
  setValueFrom(value: number): void;
  transferOrWithdrow(): void;
}

export const useTransferSoulModalControl = (): TransferSoulControlI => {
  const { playSound } = useContext(AppContext);
  const gameProf = useGameProf();
  const options = [
    { name: "Web3 Wallet", select: 0 },
    { name: "System Wallet", select: 0 },
  ];
  const dispatch = useDispatch();
  const [selectorFrom, setSelectorFrom] = useState<DDOptionI>(options[0]);
  const [valueFrom, setValueFrom] = useState<number>(0);
  const [selectorTo, setSelectorTo] = useState<DDOptionI>(options[1]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectorFrom.name === selectorTo.name) {
      setSelectorTo(options.filter((item) => item !== selectorFrom)[0]);
    }
  }, [selectorFrom.name === selectorTo.name]);

  const openBuyModal = () => {
    playSound(SoundTypeE.SMALL);
    dispatch(show(GAME_BUY_SOUL));
    dispatch(hide(GAME_TRANSFER_SOUL));
  };

  const isEnoughSoul =
    valueFrom <=
    +erc20.convertWei(
      selectorFrom.name === options[1].name
        ? gameProf.soulBalance
        : gameProf.mySoulBalance,
      "SOUL",
      4
    );

  const transferSoul = async () => {
    if (valueFrom === 0) {
      return Toasts.Info("You can't transfer 0 SOUL");
    }
    try {
      setIsLoading(true);
      const transferRes = await wallet.transferSoul(
        valueFrom.toString(),
        "SOUL"
      );
      const depResponse = await deposit({
        Amount: new BigNumber(valueFrom).toString(10),
        TxHash: transferRes.transactionHash,
      });

      if (!isServerError(depResponse)) {
        setIsLoading(false);
        dispatch(hide(GAME_TRANSFER_SOUL));
        Toasts.Info(
          `Please wait while ${valueFrom} SOUL is being transferred`,
          true
        );
      } else {
        Toasts.Error(depResponse.message);
      }
    } catch (e: any) {
      if (e.code === 4001) {
        Toasts.Error(e.message);
        setIsLoading(false);
        return;
      }
    }
  };

  const withdrowSoul = async () => {
    if (valueFrom === 0) {
      return Toasts.Info("You can't transfer 0 SOUL");
    }
    try {
      const res = await withdrow(valueFrom);
      setIsLoading(true);
      if (!isServerError(res)) {
        if (res.data.Commission === 0) {
          setIsLoading(false);
          return Toasts.Info(
            "You already payed the commission. Your funds will be transferred soon"
          );
        }

        const transferRes = await wallet.transferEth(
          res.data.Commission.toString(),
          WALLET_FOR_BUY_SOUL
        );

        if (transferRes !== undefined && transferRes.status) {
          setIsLoading(false);
          dispatch(hide(GAME_TRANSFER_SOUL));
          Toasts.Info(
            `Please wait while ${valueFrom} SOUL is being transferred`,
            true
          );
        } else {
          console.error("ERROR = ", transferRes);
          Toasts.Error(
            `Some error with your transaction. Please, try again later`
          );
        }
      } else {
        Toasts.Error(res.message);
        setIsLoading(false);
      }
    } catch (e: any) {
      let err: Error;
      if (typeof e.message === 'string') {
        err = Error(e.message);
      } else {
        err = mkError(e);
      }
      if (err.message === "[object Object]") err.message = "Unknown error";
      Toasts.Error(err.message);
      setIsLoading(false);
      return;
    }
  };

  const transferOrWithdrow = () =>
    selectorFrom.name === options[0].name ? transferSoul() : withdrowSoul();

  return {
    gameProf,
    options,
    selectorFrom,
    valueFrom,
    selectorTo,
    isSysWallet: selectorFrom.name === options[1].name,
    isEnoughSoul,
    isLoading,

    openBuyModal,
    setSelectorFrom,
    setValueFrom,
    transferOrWithdrow,
  };
};
