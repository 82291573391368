import { GameChallengeModalPropsI } from "./GameChallengeModal.props";
import cn from "classnames";
import styles from "./GameChallengeModal.module.scss";
import { ModalHeader } from "../GameModal";
import WithGameModal from "../../../../HOC/GameHOC/withGameModal";
import { GAME_CHALLENGE_MODAL } from "../../../../../utils/modalGameNames";
import GameBtn from "../../../GameFolder/GameBtn/GameBtn";
import { BtnType } from "../../../GameFolder/GameBtn/GameBtn.props";
import { gmtTime } from "../../../../../utils/gmtTime";
import { lastBattles } from "../../../../../utils/lastResults";
import reduceAddress from "../../../../../utils/reduceAddress";
import { useGameProf } from "../../../../../utils/hooks/gameHooks/useGameProf";
import { soulFromWei } from "../../../../../utils/soulFromWei";
import { GameToasts } from "../../../../../utils/gameToasts";

function GameChallengeModal({
  roomId,
  roomTime,
  bet,
  winRate,
  player,
  goTo,
  ...props
}: GameChallengeModalPropsI) {
  const gameProf = useGameProf();
  const connectToRoom = () => {
    if (+soulFromWei(gameProf.soulBalance) < 10) {
      return GameToasts.Error(
        "Please Top Up the SOUL token on system wallet and try again" // сменил текст по заданию ay
      );
    }
    goTo();
  };

  return (
    <>
      <ModalHeader
        title="PVP  |  Challenge ?"
        subtitle={`Battle ID: ${roomId}`}
      />
      <div className={styles.wrapper}>
        <p>Use Token</p>
        <h4>{bet}</h4>

        <div className={styles.host_wrapper}>
          <label> Player </label>
          <span className={styles.fee}>Service Fee: 10 SOUL</span>
          <div className={styles.host}>
            <p> {reduceAddress(player)} </p>
            <div className={styles.winrate}>
              {lastBattles(winRate).map((item, i) => item)}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <span>Open Room Time : {gmtTime(roomTime)}</span>
          <GameBtn type={BtnType.LIGHT} onClick={connectToRoom}>
            Confirm
          </GameBtn>
        </div>
      </div>
    </>
  );
}

export default WithGameModal(GAME_CHALLENGE_MODAL, GameChallengeModal);
